$theme-colors: (
  "primary": #ee7268,
  "dark": #262525,
  "danger": #ee7268,
  "success": #09baa6
);
$font-size-base: 1rem;
$font-family-sans-serif: "Titillium Web", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

@import "node_modules/bootstrap/scss/bootstrap.scss";
small {
  font-size: smaller;
}

body {
  font-size: 1rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:focus {
  outline: none;
}

.pb-40vh {
  padding-bottom: 40vh;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.profile-photo {
  border-width: 0px;
}

.profile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  background-color: rgba(0, 0, 0, 0.4);
}

.profile-photo:hover {
  border-width: 2px;
}

.profile-photo:hover .profile-overlay {
  opacity: 1;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.nowrap {
  white-space: nowrap;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.outline-0 {
  outline: none !important;
}

.w-inherit {
  width: inherit;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.pointer-events-none {
  pointer-events: none;
}

.hill-drop-shadow {
  filter: drop-shadow(0 0 1rem rgba(0, 0, 0, 0.6));
}

.z-1 {
  z-index: 1;
}

canvas:focus {
  outline: none;
}

legend {
  width: initial;
}
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
select:-internal-autofill-selected,
.MuiInputBase-root input:-webkit-autofill,
.MuiInputBase-root input:-webkit-autofill:hover,
.MuiInputBase-root input:-webkit-autofill:focus,
.MuiInputBase-root input:-webkit-autofill:active,
.MuiInputBase-root input:-internal-autofill-selected {
  box-shadow: 0 0 0 1000px #424242 inset !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {
  to {
    color: #fff !important;
    background: transparent;
  }
}

.summary-card {
  border-top: 8px solid !important;
}
.summary-card-active {
  border-top: 8px solid #ee7268 !important;
}

a:hover {
  color: #adb5bd;
  text-decoration: none;
}

.MuiDialog-scrollBody {
  -webkit-overflow-scrolling: touch;
}
@mixin fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.crosshair-v {
  @include fill;
  width: 1px;
  border-left: 1px dashed #eee;
  opacity: 0.2;
  pointer-events: none;
}
.crosshair-h {
  @include fill;
  height: 1px;
  border-top: 1px dashed #eee;
  opacity: 0.2;
  pointer-events: none;
}
.mapboxgl-user-location-dot {
  transform: matrix3d(
    1,
    -0.02,
    0,
    0,
    0.02,
    0.64,
    0.77,
    0,
    0,
    -0.77,
    0.64,
    0,
    0,
    0,
    0,
    1
  );
  -webkit-transform: matrix3d(
    1,
    -0.02,
    0,
    0,
    0.02,
    0.64,
    0.77,
    0,
    0,
    -0.77,
    0.64,
    0,
    0,
    0,
    0,
    1
  );
}

@media all and (min-width: 768px) {
  .h-md-250px {
    height: 250px;
  }
  .w-md-initial {
    width: initial !important;
  }
}

.skeleton {
  animation: skeleton 1.5s ease-in-out infinite;
}

@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.text-underline-hover:hover {
  text-decoration: underline !important;
}
