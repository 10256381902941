#folliage-back {
  animation: to-left 8s linear infinite;
  animation-fill-mode: forwards;
}
#clouds {
  animation: to-left 15s linear infinite;
  animation-fill-mode: forwards;
}
#dunes {
  animation: dunes-left 20s linear infinite;
  animation-fill-mode: forwards;
}

@keyframes dunes-left {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-170%);
  }
}
@keyframes to-left {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-100%);
  }
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #cccccc;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f9d99d;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fefefe;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #cfa97b;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f7ce99;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #2b5f5f;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #cfa97c;
}
.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #81a28e;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #c56350;
}
.st9 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #a84e2b;
}
.st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f5ba4a;
}
.st11 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f4b94a;
}
.st12 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f4b84a;
}
.st13 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #458683;
}
.st14 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #397573;
}
.st15 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #a18a85;
}
.st16 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #7f706d;
}
.st17 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #c63e42;
}
.st18 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f8ce99;
}
.st19 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #d5693d;
}
